import React, { createContext, type FC } from 'react';

import { type NormalizedWidgetProps } from '../../types';

export const WidgetContext = createContext<NormalizedWidgetProps | null>(null);

export const WidgetContextProvider: FC<
  React.PropsWithChildren<NormalizedWidgetProps>
> = (props) => (
  <WidgetContext.Provider value={props}>
    {props.children}
  </WidgetContext.Provider>
);
