import React, { type FC, useEffect, useRef } from 'react';

import Toast from '../../common/components/Toast';
import { useWidget } from '../widgets';

const toastFadeOutDelay = 5000;

// TODO: Wrap Toast with Loadable afterwards. https://jira.wixpress.com/browse/MA-2915
export const ToastContextProvider: FC<React.PropsWithChildren> = ({
  children,
}) => {
  const displayTimeout = useRef<ReturnType<typeof setTimeout> | undefined>();
  const { ui, handlers } = useWidget();

  const { toast } = ui;
  const { hideToast } = handlers;

  useEffect(() => {
    clearTimeout(displayTimeout.current);

    if (toast.isVisible) {
      displayTimeout.current = setTimeout(hideToast, toastFadeOutDelay);
    }

    return () => clearTimeout(displayTimeout.current);
  }, [toast, hideToast]);

  return (
    <>
      <Toast />
      {children}
    </>
  );
};
