import type { CustomCssVarsFn } from '@wix/yoshi-flow-editor';

import { BadgeLayout, BadgeSize, ProfileLayout } from '../../../types';

const badgeWrapperIconOnlySizes = {
  [BadgeSize.Small]: '32px',
  [BadgeSize.Medium]: '36px',
  [BadgeSize.Large]: '40px',
};

const badgeWithNameHeights = {
  [BadgeSize.Small]: '20px',
  [BadgeSize.Medium]: '24px',
  [BadgeSize.Large]: '24px',
};

const badgeSizes = {
  [BadgeSize.Small]: '12px',
  [BadgeSize.Medium]: '16px',
  [BadgeSize.Large]: '16px',
};

export const getBadgesCustomCssVars: CustomCssVarsFn = ({
  styleParams,
  isMobile,
}) => {
  const { numbers, fonts, colors } = styleParams;
  const profileLayout = numbers.profileLayout as ProfileLayout;
  const remainderBadgeColor = isMobile
    ? colors['pw-name-color-mobile']
    : profileLayout === ProfileLayout.Card
    ? colors['text-color-primary']
    : colors['pw-responsive-name-color'];

  const badgeFontWixParam = fonts['badge-font'];
  const badgeFont = badgeFontWixParam.family.join(',');

  const _badgeSize = numbers['badge-size'] as BadgeSize;
  const badgeSize = isMobile ? BadgeSize.Small : _badgeSize;

  const badgeLayout = numbers['badge-layout'] as BadgeLayout;
  const iconOnlyBadge = badgeLayout === BadgeLayout.IconOnly;
  const nameOnlyBadge = badgeLayout === BadgeLayout.NameOnly;

  const remainderWrapperHeight = iconOnlyBadge
    ? badgeWrapperIconOnlySizes[badgeSize]
    : badgeWithNameHeights[badgeSize];

  const badgeIconHeight = iconOnlyBadge
    ? badgeWithNameHeights[badgeSize]
    : badgeSizes[badgeSize];
  const badgeIconWidth = iconOnlyBadge
    ? badgeWithNameHeights[badgeSize]
    : 'initial';
  const badgeIconMaxWidth = iconOnlyBadge
    ? badgeWithNameHeights[badgeSize]
    : '30px';
  const badgeIconMargin = iconOnlyBadge
    ? badgeWithNameHeights[badgeSize]
    : '0 4px 0 0';

  const badgeTextDisplay = iconOnlyBadge ? 'none' : 'initial';
  const badgeDefaultIconDisplay = iconOnlyBadge ? 'initial' : 'none';
  const badgeIconDisplay = nameOnlyBadge ? 'none' : 'flex';

  const remainderWrapperMinWidth = iconOnlyBadge
    ? badgeWrapperIconOnlySizes[badgeSize]
    : '30px';

  const badgeWrapperPadding = iconOnlyBadge ? '0' : '0 8px';
  const badgeWrapperHeight = iconOnlyBadge
    ? badgeWrapperIconOnlySizes[badgeSize]
    : badgeWithNameHeights[badgeSize];
  const badgeWrapperWidth = iconOnlyBadge
    ? badgeWrapperIconOnlySizes[badgeSize]
    : 'initial';

  return {
    badgeFont,
    badgeIconHeight,
    badgeIconWidth,
    badgeIconMaxWidth,
    badgeIconMargin,
    remainderWrapperHeight,
    remainderWrapperMinWidth,
    badgeWrapperHeight,
    badgeWrapperWidth,
    badgeWrapperPadding,
    badgeTextDisplay,
    badgeIconDisplay,
    badgeDefaultIconDisplay,
    remainderBadgeColor,
  };
};
