import type { CustomCssVarsFn } from '@wix/yoshi-flow-editor';

import type { ProfileImageSize, ProfileLayout } from '../../../types';
import { ProfileImage, profileImageDimensionsMap } from '../../../types';

export const getProfileImageCustomCssVars: CustomCssVarsFn = ({
  styleParams,
}) => {
  const { numbers } = styleParams;
  const profileImageSize = numbers.profileImageSize as ProfileImageSize;
  const profileLayout = numbers.profileLayout as ProfileLayout;
  const pictureStyle = numbers.pictureStyle as ProfileImage;

  const profileImageSizeInLayout =
    profileImageDimensionsMap[profileLayout][profileImageSize];

  const profileImageDisplay =
    pictureStyle === ProfileImage.None ? 'none' : 'block';

  const profileImageBorderRadius =
    pictureStyle === ProfileImage.Round ? '50%' : 'initial';

  return {
    profileImageSize: `${profileImageSizeInLayout}px`,
    profileImageDisplay,
    profileImageBorderRadius,
  };
};
