import { useTranslation } from '@wix/yoshi-flow-editor';
import loadable from '@wix/yoshi-flow-editor/loadable';
import type { FC } from 'react';
import React from 'react';

import { ModalsContextProvider } from '../../../contexts/modals';
import { ToastContextProvider } from '../../../contexts/toast';
import { WidgetContextProvider } from '../../../contexts/widgets';
import withDataSync from '../../../hoc/DataSync/WithDataSync';
import forDevice from '../../../hoc/ForDevice/forDevice';
import type {
  BaseComponentProps,
  SignUpProps,
  WidgetProps,
} from '../../../types';
import { DataHook } from '../../../types';

const MobileWidget: FC<BaseComponentProps> = loadable(() => {
  return import(/* webpackChunkName: "MobileRootWidget" */ './MobileWidget');
});

const DesktopWidget: FC<BaseComponentProps> = loadable(() => {
  return import(/* webpackChunkName: "DesktopRootWidget" */ './DesktopWidget');
});

const SignUp: FC<SignUpProps> = loadable(() => {
  return import(/* webpackChunkName: "SignupRootWidget" */ './SignUpWidget');
});

const Widget = withDataSync(forDevice(MobileWidget, DesktopWidget));

const Wrapper: FC<React.PropsWithChildren<WidgetProps>> = (props) => {
  const { t, i18n } = useTranslation();
  // We may receive empty object as a props. Some bug in a platform.
  // https://wix.slack.com/archives/CSBGHP6TW/p1610727081000700
  if (!props.site) {
    return null;
  }

  const { member, isCurrentUserAuthenticated, handlers, isRTL } = props;
  const hasSocialViewedMember = props.site.isSocial && member;
  const shouldShowPW = isCurrentUserAuthenticated || hasSocialViewedMember;

  return (
    <div data-hook={DataHook.WidgetWrapper} dir={isRTL ? 'rtl' : 'ltr'}>
      {shouldShowPW && member ? (
        <section
          aria-live="polite"
          aria-label={t('profile-widget.profile-section.aria-label', {
            member: member.name,
          })}
        >
          <WidgetContextProvider {...props} member={member}>
            <ModalsContextProvider>
              <ToastContextProvider>
                <Widget {...props} t={t} member={member} i18n={i18n} />
              </ToastContextProvider>
            </ModalsContextProvider>
          </WidgetContextProvider>
        </section>
      ) : (
        <SignUp isMobile={props.isMobile} t={t} onClick={handlers.signUp} />
      )}
    </div>
  );
};

export default Wrapper;
