import React, {
  createContext,
  type FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import type { ModalComponent, ModalContextType } from '../../types';
import { classes } from './Provider.st.css';

export const ModalsContext = createContext<ModalContextType | null>(null);

export const ModalsContextProvider: FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [Modal, setModal] = useState<ModalComponent | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (Modal) {
      setIsOpen(true);
    }
  }, [Modal]);

  useEffect(() => {
    if (!isOpen) {
      setModal(null);
    }
  }, [isOpen]);

  const value = useMemo(
    () => ({
      showModal: <T,>(modal: ModalComponent<T>) => {
        setModal(() => modal as ModalComponent);
      },
      hideModal: () => setIsOpen(false),
    }),
    [],
  );

  const onModalClose = useCallback(() => setIsOpen(false), []);

  return (
    <ModalsContext.Provider value={value}>
      <>
        {children}
        {Modal && (
          <div className={classes.modalWrapper}>
            <Modal isOpen={isOpen} onClose={onModalClose} />
          </div>
        )}
      </>
    </ModalsContext.Provider>
  );
};
