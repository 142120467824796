import { useContext } from 'react';

import { WidgetContext } from './Provider';

export const useWidget = () => {
  const context = useContext(WidgetContext);

  if (!context) {
    throw new Error('[MA] Profile Card widget context is not defined');
  }

  return context;
};
