export const BLOCKED_MEMBER_ID = '-1';
export const MEMBERS_AREA_PAGE_TITLE_ID = 'members-area-page-title';

export enum MAVersion {
  v1 = 1,
  v2,
  v3,
}

export enum FFChange {
  Increase = 1,
  Decrease = -1,
}
